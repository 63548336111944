<template>
    <client-page>
        <guide-visual v-bind="visual" />

        <section class="position-relative">
            <scrolldown-tail class="white--text" />
            <v-card width="100%" height="100%" flat tile img="/images/guide/company/section-bg.jpg" class="py-60 py-lg-120 white--text">
                <v-container>
                    <v-row class="row--xxl">
                        <v-col cols="12" lg="6">
                            <h2 class="tit tit--md" data-aos="fade-left">
                                위드캔복지재단만의 강점을 바탕으로 <br />
                                사회공헌 활동을 진행합니다
                            </h2>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-row align="center">
                                <v-col cols="12">
                                    <h3 class="tit tit--sm">위드캔복지재단의 파트너십 강점</h3>
                                </v-col>
                                <v-col v-for="(strength, index) in strengths" :key="index" cols="12">
                                    <v-row align="center" class="row--sm">
                                        <v-col cols="auto">
                                            <v-sheet width="110" color="rgba(0,0,0,0.2)" rounded="circle">
                                                <v-responsive :aspect-ratio="1">
                                                    <div v-html="strength.title" class="d-flex justify-center align-center h-100 text-center page-text page-text--lg font-weight-bold white--text" />
                                                </v-responsive>
                                            </v-sheet>
                                        </v-col>
                                        <v-col>
                                            <v-row class="row--xxs">
                                                <v-col v-if="strength.text" cols="12">
                                                    <p v-html="strength.text" class="page-text white--text" />
                                                </v-col>
                                                <v-col v-if="strength.ol" cols="12">
                                                    <v-row v-for="(li, index) in strength.ol" :key="index" no-gutters class="page-text white--text">
                                                        <v-col cols="auto">{{ li.order }}&nbsp;</v-col>
                                                        <v-col>
                                                            {{ li.text }}
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col v-if="strength.ul" cols="12">
                                                    <v-row v-for="(li, index) in strength.ul" :key="index" no-gutters class="page-text page-text--sm white--text">
                                                        <v-col cols="auto">-&nbsp;</v-col>
                                                        <v-col>
                                                            {{ li }}
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </section>

        <div class="client-content-wrap">
            <section class="client-section">
                <v-container>
                    <v-row>
                        <v-col v-for="(item, index) in items" :key="index" cols="12" lg="4">
                            <v-card max-width="384" outlined color="#ddd" class="border-radius-16 h-100 text-center mx-auto">
                                <v-img :src="item.image" />
                                <v-card-title class="tit tit--sm line-height-1 justify-center primary--text text--darken-2 pt-lg-40">
                                    {{ item.title }}
                                </v-card-title>
                                <v-card-text class="page-text page-text--lg">
                                    <p v-html="item.texts" />
                                </v-card-text>
                                <v-card-text class="page-text primary--text pt-0 pb-lg-40">
                                    {{ item.plus }}
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </section>

            <section class="client-section">
                <v-container>
                    <div class="section-title-wrap" data-aos="fade-left">
                        <h2 class="section-title">파트너십 과정</h2>
                    </div>

                    <v-row class="process-list row--lg">
                        <v-col v-for="(item, index) in process" :key="index" cols="12" lg="3">
                            <v-card color="#FAFAFA" flat class="process-card">
                                <v-row class="text-center">
                                    <v-col cols="12">
                                        <div class="primary--text font-weight-bold font-size-28">0{{ index + 1 }}</div>
                                        <div v-html="item.name" class="client-title--xs font-weight-bold mt-16" />
                                        <v-img :src="`/images/guide/company/progress-${index + 1}.svg`" max-width="60" class="mt-28 mx-auto" />
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-simple-table class="process-table">
                        <tr v-for="(item, index) in process" :key="index">
                            <th>
                                {{ item.name }}
                            </th>
                            <td>
                                {{ item.text }}
                            </td>
                        </tr>
                    </v-simple-table>
                </v-container>
            </section>

            <section class="client-section">
                <v-container>
                    <div class="section-title-wrap" data-aos="fade-left">
                        <h2 class="section-title">후원기업 나눔스토리</h2>
                    </div>

                    <v-row>
                        <v-col v-for="(story, index) in stories" :key="index" cols="12" md="4">
                            <v-card max-width="384" outlined color="#ddd" class="border-radius-16 mx-auto">
                                <v-img :src="story.image" alt="" />
                                <v-card-title class="px-lg-24 pt-lg-24">
                                    {{ story.title }}
                                </v-card-title>
                                <v-card-text class="pb-0 px-lg-24">
                                    <v-divider />
                                </v-card-text>
                                <v-card-text class="px-lg-24 pb-lg-24">
                                    {{ story.date }}
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </section>
        </div>
    </client-page>
</template>

<script>
import GuideVisual from "@/components/client/guide/guide-visual.vue";
import ClientPage from "../templates/ClientPage.vue";
import ScrolldownTail from "@/components/dumb/scrolldown-tail.vue";

export default {
    components: {
        ClientPage,
        GuideVisual,
        ScrolldownTail,
    },
    data() {
        return {
            visual: {
                image: "/images/guide/company/visual.jpg",
                title: "기업후원",
                texts: "위드캔복지재단과 함께 기업의 사회적 책임(CSR)을 실천하세요. <br />당신의 기업에 꼭 맞는 든든한 사회공헌 파트너, 위드캔복지재단이 함께합니다.",
                textImage: {
                    text: "함꼐하는 가치, 더 큰 변화",
                    image: "/images/guide/company/textImage.svg",
                    size: "302",
                },
                white: true,
            },

            strengths: [
                {
                    title: "전문가 <br />조직",
                    text: "기업별 전담 CSR 매니저 배치 및 운영",
                    ul: [
                        "사회공헌 및 사회복지 전문가로 구성된 전문 조직",
                        "분야별 맞춤형 담당자 지정으로 효과적인 사회공헌 활동 지원",
                        //
                    ],
                },
                {
                    title: "다양한 <br />파트너십",
                    text: "기업 가치에 부합하는 맞춤형 사회공헌 컨설팅 제공 <br />사회적 이슈와 기업의 전략을 결합한 후원 규모 설계 및 실행 <br />사회문제 해결을 위해 기업과 함께 협력하는 지속 가능한 파트너십 구축",
                },
                {
                    title: "협력 <br />네트워크",
                    ol: [
                        {
                            order: "가.",
                            text: "다양한 계층 및 분야 지원: 아동, 장애인, 노인, 여성, 다문화 가정 등 소외 계층 대상 폭넓은 지원",
                        },
                        {
                            order: "나.",
                            text: "국내 및 해외 네트워크",
                        },
                    ],
                    ul: [
                        "국내 26개 산하시설 및 해외 1개국 사업 운영",
                        "다양한 사회복지기관과 협력하여 폭넓은 연계 서비스를 제공",
                        "복지 사각지대 해소를 위한 체계적이고 포괄적인 지원 시스템 구축",
                        //
                    ],
                },
                {
                    title: "투명성",
                    ul: [
                        "국세청 ‘공익법인 결산 서류 등 공시 시스템’ 재무 정보 공개",
                        "매년 회계법인 및 내부감사를 통해 재무와 사업 운영을 점검",
                        //
                    ],
                },
            ],

            items: [
                {
                    image: "/images/guide/company/item-1.jpg",
                    title: "기업•임직원 기부",
                    texts: "기업 및 임직원이 조성한 사회공헌 기금을 통해 <br class='d-none d-lg-block' />소외된 이웃을 지원하고, 기업의 가치실현과 <br class='d-none d-lg-block' />나눔문화를 확산시킵니다.",
                    plus: "(사회공헌 기금 후원, 매칭그랜트 후원)",
                },
                {
                    image: "/images/guide/company/item-2.jpg",
                    title: "물품후원",
                    texts: "기업에서 구매, 생산된 물품을 후원받아 <br class='d-none d-lg-block' />국내, 해외 어려운 이웃을 위해 지원합니다.",
                },
                {
                    image: "/images/guide/company/item-3.jpg",
                    title: "기업•임직원 기부",
                    texts: "기업 및 임직원이 조성한 사회공헌 기금을 통해 소외된 이웃을 지원하고, 기업의 가치실현과 나눔문화를 확산시킵니다.",
                    plus: "(사회공헌 기금 후원, 매칭그랜트 후원)",
                },
            ],
            process: [
                {
                    name: "기업 후원 문의 및 검토",
                    text: "기업의 Needs(필요)에 맞춘 사회공헌 컨설팅을 통해 파트너십 가능 여부를 검토합니다.",
                },
                {
                    name: "파트너십 협의 및 체결",
                    text: "기업 맞춤형 사회공헌 사업을 상호 제안 및 협의 후 파트너십을 체결합니다.",
                },
                {
                    name: "사업 실행",
                    text: "지원 대상 발굴, 전달식 진행, 임직원 봉사활동 등 사업 전반의 활동을 체계적으로 수행합니다.",
                },
                {
                    name: "사업 보고",
                    text: "사업 시행 후 평가를 진행하며, 후원 기업에 활동 결과를 투명하게 보고합니다.",
                },
            ],
            stories: [
                {
                    image: "/images/guide/company/story-1.jpg",
                    title: "위드-캔복지재단, 캄보디아 현지 NGO 3곳과 협약 체결 및 사업장 방문",
                    date: "2022.12.05",
                },
                {
                    image: "/images/guide/company/story-2.jpg",
                    title: "위드캔복지재단, 사단법인 희망그림과 업무협약 체결",
                    date: "2022.12.05",
                },
                {
                    image: "/images/guide/company/story-3.jpg",
                    title: "위드캔복지재단, (재)한국산업 의료복지연구원과 업무협약 체결",
                    date: "2022.12.05",
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.process-list {
    > [class*="col"] {
        .process-card {
            padding: 40px;
            height: 100%;
            border-radius: 16px;
            max-width: 400px;
            margin: 0 auto;
            word-break: keep-all;
        }
        &:not(:last-child) {
            .process-card {
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    right: 50%;
                    bottom: -28px;
                    transform: translateX(50%) rotate(90deg);
                    width: 24px;
                    height: 24px;
                    background: url(/images/guide/temporary/process-arrow.svg) no-repeat center;
                }
            }
        }
    }
}
.process-table {
    margin-top: 40px;
    border-top: 2px solid #111;
    border-radius: 0;
    tr {
        border-bottom: 1px solid #ececec;
    }
    th,
    td {
        padding: 12px 16px;
    }
    th {
        font-weight: 500;
    }
    td {
        color: #666;
    }
}
@media (min-width: 768px) {
    .process-list {
        > [class*="col"] {
            &:not(:last-child) {
                .process-card {
                    position: relative;
                    &::after {
                        content: "";
                        position: absolute;
                        right: -32px;
                        bottom: 50%;
                        transform: translatey(50%);
                        width: 24px;
                        height: 24px;
                        background: url(/images/guide/temporary/process-arrow.svg) no-repeat center;
                    }
                }
            }
        }
    }
    .process-table {
        margin-top: 82px;
        th,
        td {
            padding: 16px 20px;
        }
        th {
            width: 200px;
        }
    }
}
@media (min-width: 1024px) {
}
</style>