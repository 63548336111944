var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('guide-visual', _vm._b({}, 'guide-visual', _vm.visual, false)), _c('section', {
    staticClass: "position-relative"
  }, [_c('scrolldown-tail', {
    staticClass: "white--text"
  }), _c('v-card', {
    staticClass: "py-60 py-lg-120 white--text",
    attrs: {
      "width": "100%",
      "height": "100%",
      "flat": "",
      "tile": "",
      "img": "/images/guide/company/section-bg.jpg"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--xxl"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('h2', {
    staticClass: "tit tit--md",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_vm._v(" 위드캔복지재단만의 강점을 바탕으로 "), _c('br'), _vm._v(" 사회공헌 활동을 진행합니다 ")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "tit tit--sm"
  }, [_vm._v("위드캔복지재단의 파트너십 강점")])]), _vm._l(_vm.strengths, function (strength, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-row', {
      staticClass: "row--sm",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-sheet', {
      attrs: {
        "width": "110",
        "color": "rgba(0,0,0,0.2)",
        "rounded": "circle"
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 1
      }
    }, [_c('div', {
      staticClass: "d-flex justify-center align-center h-100 text-center page-text page-text--lg font-weight-bold white--text",
      domProps: {
        "innerHTML": _vm._s(strength.title)
      }
    })])], 1)], 1), _c('v-col', [_c('v-row', {
      staticClass: "row--xxs"
    }, [strength.text ? _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('p', {
      staticClass: "page-text white--text",
      domProps: {
        "innerHTML": _vm._s(strength.text)
      }
    })]) : _vm._e(), strength.ol ? _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, _vm._l(strength.ol, function (li, index) {
      return _c('v-row', {
        key: index,
        staticClass: "page-text white--text",
        attrs: {
          "no-gutters": ""
        }
      }, [_c('v-col', {
        attrs: {
          "cols": "auto"
        }
      }, [_vm._v(_vm._s(li.order) + " ")]), _c('v-col', [_vm._v(" " + _vm._s(li.text) + " ")])], 1);
    }), 1) : _vm._e(), strength.ul ? _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, _vm._l(strength.ul, function (li, index) {
      return _c('v-row', {
        key: index,
        staticClass: "page-text page-text--sm white--text",
        attrs: {
          "no-gutters": ""
        }
      }, [_c('v-col', {
        attrs: {
          "cols": "auto"
        }
      }, [_vm._v("- ")]), _c('v-col', [_vm._v(" " + _vm._s(li) + " ")])], 1);
    }), 1) : _vm._e()], 1)], 1)], 1)], 1);
  })], 2)], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "client-content-wrap"
  }, [_c('section', {
    staticClass: "client-section"
  }, [_c('v-container', [_c('v-row', _vm._l(_vm.items, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "lg": "4"
      }
    }, [_c('v-card', {
      staticClass: "border-radius-16 h-100 text-center mx-auto",
      attrs: {
        "max-width": "384",
        "outlined": "",
        "color": "#ddd"
      }
    }, [_c('v-img', {
      attrs: {
        "src": item.image
      }
    }), _c('v-card-title', {
      staticClass: "tit tit--sm line-height-1 justify-center primary--text text--darken-2 pt-lg-40"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('v-card-text', {
      staticClass: "page-text page-text--lg"
    }, [_c('p', {
      domProps: {
        "innerHTML": _vm._s(item.texts)
      }
    })]), _c('v-card-text', {
      staticClass: "page-text primary--text pt-0 pb-lg-40"
    }, [_vm._v(" " + _vm._s(item.plus) + " ")])], 1)], 1);
  }), 1)], 1)], 1), _c('section', {
    staticClass: "client-section"
  }, [_c('v-container', [_c('div', {
    staticClass: "section-title-wrap",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_c('h2', {
    staticClass: "section-title"
  }, [_vm._v("파트너십 과정")])]), _c('v-row', {
    staticClass: "process-list row--lg"
  }, _vm._l(_vm.process, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "lg": "3"
      }
    }, [_c('v-card', {
      staticClass: "process-card",
      attrs: {
        "color": "#FAFAFA",
        "flat": ""
      }
    }, [_c('v-row', {
      staticClass: "text-center"
    }, [_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('div', {
      staticClass: "primary--text font-weight-bold font-size-28"
    }, [_vm._v("0" + _vm._s(index + 1))]), _c('div', {
      staticClass: "client-title--xs font-weight-bold mt-16",
      domProps: {
        "innerHTML": _vm._s(item.name)
      }
    }), _c('v-img', {
      staticClass: "mt-28 mx-auto",
      attrs: {
        "src": `/images/guide/company/progress-${index + 1}.svg`,
        "max-width": "60"
      }
    })], 1)], 1)], 1)], 1);
  }), 1), _c('v-simple-table', {
    staticClass: "process-table"
  }, _vm._l(_vm.process, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('th', [_vm._v(" " + _vm._s(item.name) + " ")]), _c('td', [_vm._v(" " + _vm._s(item.text) + " ")])]);
  }), 0)], 1)], 1), _c('section', {
    staticClass: "client-section"
  }, [_c('v-container', [_c('div', {
    staticClass: "section-title-wrap",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_c('h2', {
    staticClass: "section-title"
  }, [_vm._v("후원기업 나눔스토리")])]), _c('v-row', _vm._l(_vm.stories, function (story, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('v-card', {
      staticClass: "border-radius-16 mx-auto",
      attrs: {
        "max-width": "384",
        "outlined": "",
        "color": "#ddd"
      }
    }, [_c('v-img', {
      attrs: {
        "src": story.image,
        "alt": ""
      }
    }), _c('v-card-title', {
      staticClass: "px-lg-24 pt-lg-24"
    }, [_vm._v(" " + _vm._s(story.title) + " ")]), _c('v-card-text', {
      staticClass: "pb-0 px-lg-24"
    }, [_c('v-divider')], 1), _c('v-card-text', {
      staticClass: "px-lg-24 pb-lg-24"
    }, [_vm._v(" " + _vm._s(story.date) + " ")])], 1)], 1);
  }), 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }